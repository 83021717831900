svg.custom {
    -webkit-transition: height 0.5s;
}

svg.custom .node circle {
    fill: #F3F3FF;
    stroke: #2593B8;
    stroke-width: 1.5px;
}

svg.custom .node-inactive circle {
    -webkit-transition: fill 0.5s;
    fill: grey;
    stroke: #2593B8;
    stroke-width: 1.5px;
}

svg.custom .node-focused circle {
    fill: #F3F3FF;
    stroke: #2593B8;
    stroke-width: 1.5px;
}

svg.custom .node text {
    -webkit-transition: fill, font-size 0.5s;
    font-size: 12px;
    background-color: #444;
    fill: #F4F4F4;
    text-shadow: 0 1px 4px black;
}

svg.custom .node-inactive text {
    fill: grey;
    font-size: 8px;
}

svg.custom .node-focused text {
    font-size: 16px;
}

svg.custom .node {
    cursor: pointer;
}

svg.custom path.link {
    -webkit-transition: stroke 0.5s;
    fill: none;
    stroke: #2593B8;
    stroke-width: 1.5px;
}

svg.custom path.link-inactive {
    stroke: rgba(37, 147, 184, 0.2);
}

.MuiPaper-root.MuiPopover-paper {
    padding: 10px;
    background-color: rgba(36, 36, 36, 0.95);
    color: white;
    border: #2593B8 1.5px solid;
}